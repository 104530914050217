.App {
  background-color: #fefbea;
  min-height: 100vh;
}


.App-link {
  color: #61dafb;
}

.jumbotron {
  background-color: #6d7993;
  color: white;
}

.header-bar {
  background-color: #96858f;
  color: white;
  display: flex;
  flex-direction: row;
}

.nav-link {
  color: white;
  text-decoration: none;
  margin-left: 20px;
  margin: 1rem;
  display: block;
}

.content {
  background-color: #d5d5d5;
  padding: 10px;
  border: 2px solid #9099a2;
}

.game-card-container {
  display: flex;
  flex-wrap: wrap;
}

.game-card {
  margin: 10px;
  border: 2px solid #9099a2;
  background-color: #fefbea;
  width: 225px;
  justify-content: center;
}

.game-title {
  text-align: center;
}

.game-status {
  text-align: center;
  font-size: 14px;
}

.game-port {
  text-align: center;
  font-size: 10px;
}

.online-indicator {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #0fcc45;
  border-radius: 50%;
  position: relative;
  align-self: center;
  left: 5px;
}

span.online-blink {
  display: block;
  width: 12px;
  height: 12px;
  background-color: #0fcc45;
  opacity: 0.7;
  border-radius: 50%;
  animation: online-blink 1s linear infinite;
  align-self: center;
  position: relative;
}

.offline-indicator {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #cc1c0f;
  border-radius: 50%;
  position: relative;
  align-self: center;
  left: 5px;
}

@keyframes online-blink {
  100% {
    transform: scale(2, 2);
    opacity: 0;
  }
}

.button-container {
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.project-card {
  margin: 10px;
  border: 2px solid #9099a2;
  background-color: #fefbea;
  width: 225px;
  justify-content: center;
}
.site-title {
  font-size: 28px;
  padding-left: 75px;
}

@media (max-width: 768px) {
  .game-card-container {
    justify-content: center;
  }
}